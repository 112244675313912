@import "../../scss/index.scss";

$footerHeight: 60px;
$menuWight: 200px;

.logged-layout {
  height: 100vh;
  background-color: $background-primary;

  &__content {
    display: flex;
    height: 100%;
    // height: calc(100% - $footerHeight);
  }

  &__left-menu {
    background-color: $background-dark;
    width: $menuWight;
  }

  &__children-content {
    width: calc(100% - $menuWight);
    overflow-x: none;
    overflow-y: scroll; /* Show vertical scrollbar */
    &::-webkit-scrollbar-track {
      background: $background-primary;
    }
    &::-webkit-scrollbar-thumb{
      background: $background-grey;
    }
  }
}