@import "~/src/scss/index.scss";

.div__NewOperationContent{
  .ui.multiple.dropdown>.label{
    background-color: $background-light-grey;
    color: $font-light-grey;
  }

  .ui.form .field .ui.button.primary{
    margin-top: 30px;
  }
}