@import "~/src/scss/index.scss";

.MenuLabel {
  padding-bottom: 5px;
  width: 100%;

  &__label {
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 4px;
  }

  &__placeholder {
    border-radius: 5px;
    background-color: $background-grey;
    border: 0;
    font-size: 16px;
    color: $font-grey;
    padding: 9.5px 10px 9.5px 16px;

    display: flex;
    justify-content: space-between;
  }

  &__placeholder__error {
    border-radius: 5px;
    background-color: $background-grey;
    border: 0;
    border-bottom: 1px solid #f00;
    font-size: 16px;
    color: #e0b4b4;
    padding: 10.857px 10px 10.857px 16px;

    display: flex;
    justify-content: space-between;
  }

  &__error {
    color: #fff;
    padding-left: 15px;
  }

  &__options {
    width: 70px;
    position: absolute;
    z-index: 90 !important;

    .option {
      font-size: 16px;
      background-color: $background-grey-hover;
      padding: 10px 16px;

      &:first-of-type {
        border-radius: 5px 5px 0px 0px;
      }

      &:last-of-type {
        border-radius: 0px 0px 5px 5px;
      }

      &:hover {
        background-color: $background-grey-hover-hover;
        cursor: pointer;
      }
    }
  }
}
