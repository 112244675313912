@import "~/src/scss/index";

.div__Reports {
  margin: 30px 50px;
  > h1 {
    font-size: 35px;
    color: $font-light;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }

  .divider.item {
    color : $font-light;
  }

  h4.ui.header {
    color : white;
  }

  h5.ui.header {
    color : $font-light-grey;
  }

  .ui.horizontal.divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }

  .ui.button {
    // margin: 0;
    border-radius: 5px;
    background-color: $background-grey;
    color: $font-light-grey;
  }
}