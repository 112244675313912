@import "~/src/scss/index";

.TextLabel {
  &__label {
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 4px;
  }

  &__placeholder {
    border-radius: 5px;
    background-color: $background-grey;
    border: 0;
    font-size: 16px;
    color: $font-light-grey;
    padding: 10.857px 16px;
  }

  margin-bottom: 19px;
}
