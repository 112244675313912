.ui.form {
  .field,
  .fields {
    .input {
      input {
        background-color: $background-secondary;
        border-color: $background-secondary;
        color: $font-light-grey;

        &:hover,
        &:active,
        &:focus {
          background-color: $background-secondary;
        }
      }

      i {
        color: $font-light;
      }
    }

    .dropdown {
      background-color: $background-secondary !important;
      border-color: $border-primary !important;

      &:hover {
        .menu {
          border-color: $border-primary !important;
        }
      }

      input {
        background-color: $background-secondary !important;
        border-color: $border-primary !important;
        color: $font-light !important;

        &:hover,
        &:active,
        &:focus {
          background-color: $background-secondary !important;
        }
      }

      .menu {
        background-color: $background-secondary;
        border-color: $border-primary;

        .item {
          border-color: $border-primary;
        }

        .message {
          color: $font-light;
          opacity: 0.6;
        }
      }
    }

    .ui.button.primary {
      background-color: $primary-color;
      color: $font-light;
      border-radius: 100px;
    }
  }
  
  .field.error {
    .ui.input {
      input {
        background-color: $background-secondary;
        border: 1px solid $danger;
        color: $danger;
      }

      i {
        color: $danger;
      }
    }

    .ui.dropdown {
      .text {
        background-color: transparent;
        color: $danger;
      }
    }
  }
}


// dropdown
.ui.search,
.ui.fluid, 
.ui.selection, 
.ui.search.selection {
  &.dropdown {
    background-color: $background-secondary !important;
    border-color: $border-primary !important;

    >.dropdown.icon:before{
      color: white;
    }

    &:hover {
      .menu {
        border-color: $border-primary !important;
      }
    }

    input {
      background-color: $background-secondary !important;
      border-color: $border-primary !important;
      color: $font-light !important;

      &:hover,
      &:active,
      &:focus {
        background-color: $background-secondary !important;
      }
    }

    .menu {
      background-color: $background-secondary;
      border-color: $border-primary;

      color: $font-light;
      .item {
        color: $font-light-grey;
        border-color: $border-primary;
        :hover{
          color : $font-light;
        }
      }
      .selected.item{
        color : $font-light;
      }

      .message {
        color: $font-light;
        opacity: 0.6;
      }
    }
    .dropdown.icon {
      color : white;
    }

    .text {
      color: $font-light-grey;
    }
  }
}