@import "./index.scss";
@import "./semantic/index.scss";

a,
p {
  color: $font-light;
}

#form_del_first{
  margin-top: 24px;
}

.ui.form{
  .fields{
    .wide.field{
      padding-left: 2px;
      padding-right: 2px;
    }
    margin-bottom: 4px;
  }

  .inline.fields{
    margin-bottom: 4px;
  }
}

.error-text {
  color: $font-light;
  padding-left: 15px;
}