@import "~/src/scss/index";

.div__Profile {
  padding: 40px;
  color: white;

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0;

    > div {
      display: flex;
      align-items: center;

      > span {
        margin-left: 20px;
        font-size: 20px;
      }
    }

    .ui.button {
      margin: 0;
      border-radius: 100px;
      background-color: $background-grey;
      color: $font-light-grey;
    }
  }
}