@import "~/src/scss/index";

.div__DetailsCRUD{
  #money{
    text-align: right;
  }

  #date{
    text-align: right;
  }
  
  // .ui.label:last-child {
  //   width: 100%;
  // }

  #label_first_row{
    margin-bottom: 0px !important;
  }


  .little_space{
    margin: 10px;
  }

  #open_folder{
    .ui.button{
      background-color: $background-secondary;
      color: $font-grey;
    }
  }

  .ui.multiple.dropdown>.label{
    background-color: $background-light-grey;
    color: $font-light-grey;
  }
}