@import "~/src/scss/index";

.div__BudgetCRUD{
  // text-align: center;
  // position: absolute;
  // right: 0px;
  #label_first_row{
    margin-bottom: 0px !important;
  }
}

.div__BudgetDetails{
  #colm{
    display: flex;
    align-items: right;
    align-content: right;
  }
  
  #field_budget{
    text-align: right;
    width: 100%;
    padding-right: 0;
  }

  #algo {
    align-items: right;
    align-content: right;
  }

  #input_budget{
    width : 300px;
    align-items: right;
    align-content: right;
  }
}
