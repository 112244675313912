$primary-color: #be7a2d;
$primary-color-hover: #1ed75f;

$font-light: #fff;
$font-light-grey: #c0c0c0;
$font-grey: #9e9e9e;
$font-dark: #000;

$border-primary: #282828;

$background-primary: #121212;
$background-secondary: #212121;
$background-dark: #000;
$background-light: #fff;
$background-light-grey: #555;
$background-orange: #f18903;
$background-grey: #2d2d2d;
$background-grey-dark: #090909;
$background-grey-hover: #3c3c3c;
$background-grey-hover-hover: #5c5c5c;


$danger: #b69494;