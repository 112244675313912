@import "~/src/scss/index";

.accept-modal {
  color: white;
  background-color: $background-primary !important;
  border-radius: 15px !important;

  .header {
    color: white;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    background-color: transparent !important;
    padding: 10px 5px !important;
    border-bottom: 0 !important;

    > span {
      font-size: 18px;
      color: white;
    }
  }
  i.icon,
  .i.link.icon {
    color: white;
  }
  .content {
    // color: white;
    background-color: transparent !important;
  }
}