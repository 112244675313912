@import "~/src/scss/index";

.div__LeftMenu{
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  height: 100%;

  .ui.menu.vertical.secondary.fluid {
    margin: 0;

    .item {
      border-radius: 0 !important;
      border-left: 4px solid transparent;
      color: $font-grey !important;

      i {
        color: $font-grey !important;
      }

      &.active {
        border-color: $primary-color;
        color: $font-light !important;
        i {
          color: $font-light !important;
        }
      }
    }
  }

  i.icon{
    color: white;
  }

  .left-menu-mid{
    padding-top: 20px;
  }

  .left-menu-bottom{
    position: absolute; 
    bottom: 0;
    display : flex;
    width: 200px;
  }

  .left-menu-top {
    display : flex;
    padding-top: 10px;
    padding-left : 10px;
    width: 100%;
    .top-left {
      padding-right: 10px;
    }
    .top-mid {
      
    }
    .top-right {
      position: absolute;
      left: 175px;
    }
  }
}