@import "~/src/scss/index";

.basic-modal {
  background-color: $background-primary !important;
  border-radius: 15px !important;

  .header {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    background-color: transparent !important;
    padding: 15px 5px !important;
    border-bottom: 0 !important;
    > span {
      font-size: 18px;
      color: $font-light-grey;
    }
  }

  .content {
    background-color: transparent !important;
    padding-top: 0px;
  }

  #modal_content{
    padding-top: 10px;
  }

  i.link.icon, i.link.icons {
    color: $font-light-grey;
  }

}