@import "~/src/scss/index";

.div__IncomesCRUD{
  #money{
    text-align: right;
  }
  #date{
    text-align: right;
  }
  #label_first_row{
    margin-bottom: 0px !important;
  }
}