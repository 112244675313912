@import "~/src/scss/index";


.div__RefSearch{
  display : flex;

  .ui.button{
    background-color: $background-secondary;
    height: 37px;
  }

  .ui.form .fields>.field{
    padding-left: 2px;
  }

  .ui.dropdown,
  .ui.search.dropdown{
    margin-right: 5px;
  }
}