@import "../../scss/index.scss";

.NRG-Home {
    display: flex;
    height: 50vh;
    // width: 100px;
    overflow: auto;
    vertical-align: middle;

    img {
        display: flex;
        margin: auto;
        top: 50%;
        width: 30%;
    }
}