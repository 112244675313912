@import "~/src/scss/index";

// .ui.table tr td {
//     color : black;
// }

// .references_box {
//   position: relative;
//   background-color: $background-primary;
//   // background-color: $background-grey-dark;
//   width: 100%;
//   z-index: 1;
//   padding: 30px 50px;
//   // border-radius: 10px;

//   // margin-top: 20px;
//   // margin-bottom: 30px;
//   // margin-left: 4%;
// }

.div__References {
  margin: 30px 50px;

  > h1 {
    font-size: 35px;
    color: $font-light;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }

  .ui.button{
    .login {
      text-transform: uppercase;
      width: 100%;
      margin-bottom: 30px;
      border-radius: 50px;
      padding: 20px 0;
      font-size: 17px;
      letter-spacing: 3px;
      background-color: $primary-color;
      color: $font-light;
    }
  }

  .ui.search.dropdown>input.search  {
    color: $font-light-grey;
  }
  
  .ui.dropdown{
    .text {
      color: $font-light-grey;
    }
    .menu {
      .item{
        color: $font-light-grey;
        :hover{
          color : $font-light;
        }
      }
      .selected.item{
        color : $font-light;
      }
    }
    .dropdown.icon {
      color : white;
    }
  }
  
  .divider.item {
    color : $font-light;
  }

  h4.ui.header {
      color : white;
  }

  h5.ui.header {
    color : $font-light-grey;
  }

  .ui.horizontal.divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .ui.form .field>label {
    color: $font-light-grey;
  }

  .ui.form .inline.field>:first-child, 
  .ui.form .inline.fields .field>:first-child{
    color: $font-light-grey;
  }
}